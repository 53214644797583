;(function(){

	jQuery(function(){
            
            function getQueryVariable(variable)
            {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
                        if(pair[0] == variable){return pair[1];}
                }
                return(false);
            }
            
            window.SNOWDONCAM = window.SNOWDONCAM || {};
            
            var fragment = getQueryVariable("archive")
            
            if(!!fragment){
                window.SNOWDONCAM.current = fragment;
                window.SNOWDONCAM.archive = true;
            }
            
            var parts = window.SNOWDONCAM.current.split("-");
            var date = parts[1];
            var time = parts[2];
            
            var d = date.split("");
            // var dateString = d[6] + d[7] + "/" + d[4] + d[5] + "/" + d[2] + d[3];
            var t = time.split("");
            //var timeString = t[0] + t[1] + ":" + t[2] + t[3];
            var theDate = new Date(Date.UTC(2000 + Number(d[2] + d[3]), Number(d[4] + d[5])-1, Number(d[6] + d[7]), Number(t[0] + t[1]), Number(t[2] + t[3]), 0, 0));
            
            
            var dtfT = new Intl.DateTimeFormat('en-GB', {
                hour: "numeric",
                hour12: false,
                minute: "numeric",
                //second: "numeric",
                timeZone: "Europe/London",
                timeZoneName: "short",
            })
            
            var timeString = dtfT.format(theDate)

            var dateString = new Intl.DateTimeFormat('en-GB', { timeZone: "Europe/London" }).format(theDate)






            var imgurl_base = (window.location.host == "snowdon.webcam") ? "" : "http://snowdon.webcam";
            
            var imgurl = imgurl_base + '/archive/' + window.SNOWDONCAM.current  + '-orig.jpg';

            jQuery("#image-view").append('<img style="width: 100%" src="' + imgurl_base + '/archive/' + window.SNOWDONCAM.current  + '-500.jpg" alt="current view of snowdon"/>')
            jQuery("div#image-view-1000").append('<img style="width: 100%" src="' + imgurl_base + '/archive/' + window.SNOWDONCAM.current  + '-1000.jpg" alt="current view of snowdon"/>')
            jQuery("a#img-link-orig").attr("href", imgurl)
                
            if( !!(window.SNOWDONCAM.archive) ){
                jQuery(".currentView").hide();
            }
            else {
                jQuery(".archiveView").hide();
            }
            
            jQuery("span.dateString").html(dateString + " at " + timeString)
            
            var permalink = "https://share.snowdon.webcam/v1/archive/" + window.SNOWDONCAM.current;
            var twText = encodeURIComponent("The view of Snowdon on " + dateString + " at " + timeString);
            var twUrl = encodeURIComponent(permalink);
            var twHt = "snowdon"
            var twVia = "SnowdonWebcam"
            
            jQuery("a#twitterBtn2").attr("href", "https://twitter.com/intent/tweet?" + "text=" + twText +"&url="+twUrl+"&hashtags=" + twHt + "&via="+ twVia);
            jQuery("a#facebookBtn2").attr("href", "http://www.facebook.com/sharer/sharer.php?u=" + twUrl);
            jQuery("a#permalink").attr("href",  permalink);
            jQuery("a#download").attr("href",  imgurl);

            var clip = new Clipboard("a#copy-url", {
                text: function(trigger) {
                    return permalink;
                }
            })

            clip.on('success', function(e) {
                alert("Copied!")
            });
            
            clip.on('error', function(e) {
                alert("Could not access clipboard to copy the link")
            });

        })

})()
